export const addFeeParcelVisuals = [
  {
    name: "Demo",
    markdown: `![Add fee parcel GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addFeeParcel = {
  order: 13,
  name: "Add a fee parcel",
  keywords: "fee simple",
  subcategory: "Properties",
  markdown: `# Add a fee parcel

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a property is situated on more than one unit of land, you can represent this in the property diagram by adding a {% inlineRouterLink %}fee parcel{% /inlineRouterLink %}.

  {% callout type="caution" marginStyles="mb-4" %}
  **Important**: This command saves **immediately**.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Property selector" %}property{% /inlineRouterLink %} or {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Reciprocal easements (optional)" %}reciprocal easement{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addFeeSimple" /%}. If a reciprocal easement is selected, click {% inlinePropertyDiagramIcon name="insertFeeSimple" /%}.
  4. A new fee parcel appears in the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcels section of the diagram{% /inlineRouterLink %}.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$addFeeParcelVisuals /%}
  `,
};
